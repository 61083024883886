/* GLOBAL STYLES
-------------------------------------------------- */

/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
}

/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper>.container {
  padding-right: 0;
  padding-left: 0;
}

.navbar-wrapper .navbar {
  padding-right: 15px;
  padding-left: 15px;
}

.navbar-wrapper .navbar .container {
  width: auto;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  min-height: 600px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
  text-align: center;
  margin-top: 20%;
}

.carousel-caption h1 {
  color: #fff;
  font-size: 2.8em;
  width: 80%;
  margin: 0 auto;
  font-weight: 600;
  text-shadow: 
   1px 1px #000000,
   -1px -1px #000000,
   -1px 1px #000000, 
   1px -1px #000000;
}

.carousel-caption a {
  color: #fff;
  width: 80%;
  margin: 0 auto;
  font-weight: 600;
  text-shadow: 
   1px 1px #000000,
   -1px -1px #000000,
   -1px 1px #000000, 
   1px -1px #000000;
  text-decoration: none;
}

.carousel-caption p {
  text-align: right;
  color: #fff;
  font-size: 1.5em;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  text-shadow: 
   1px 1px #000000,
   -1px -1px #000000,
   -1px 1px #000000, 
   1px -1px #000000;
}

/* Declare heights because of positioning of img element */
.carousel .item {
  min-height: 600px;
  background-color: #777;
}

.carousel-inner>.item>img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 600px;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center;
}

.marketing h2 {
  font-weight: normal;
}

.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px;
}

/* Featurettes
------------------------- */
.featurette-divider {
  margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px;
}

/* RESPONSIVE CSS
-------------------------------------------------- */
@media ( min-width : 768px) {
  /* Navbar positioning foo */
  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0;
  }

  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media ( min-width : 992px) {
  .featurette-heading {
    margin-top: 120px;
  }
}

@media ( max-width :1024px) {
  .carousel {
    min-height: 550px;
  }
  .carousel .item {
    min-height: 550px;
  }
  .carousel-inner>.item>img {
    min-height: 550px;
  }
  .carousel-caption h1 {
    font-size: 2.5em;
  }
}

@media ( max-width :768px) {
  .carousel {
    min-height: 400px;
  }
  .carousel .item {
    min-height: 400px;
  }
  .carousel-inner>.item>img {
    min-height: 400px;
  }
  .carousel-caption h1 {
    font-size: 2.1em;
  }
}

@media ( max-width :640px) {
  .carousel {
    min-height: 350px;
  }
  .carousel .item {
    min-height: 350px;
  }
  .carousel-inner>.item>img {
    min-height: 350px;
  }
  .carousel-caption h1 {
    font-size: 1.8em;
  }
}

@media ( max-width :480px) {
  .carousel {
    min-height: 280px;
  }
  .carousel .item {
    min-height: 280px;
  }
  .carousel-inner>.item>img {
    min-height: 280px;
  }
  .carousel-caption h1 {
    font-size: 1.55em;
  }
}

@media ( max-width :320px) {
  .carousel {
    min-height: 192px;
  }
  .carousel .item {
    min-height: 192px;
  }
  .carousel-inner>.item>img {
    min-height: 192px;
  }
  .carousel-caption h1 {
    font-size: 1.2em;
    width: 100%;
  }
  .carousel-caption {
    margin-top: 22%;
  }
}
